@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.snow.css';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body { height: 100%; overflow: auto;}
body
{
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.example-spacer {
  flex: 1 1 auto;
}

/*
.mat-mdc-raised-button {
  margin-left: 5px;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  background-color: #fff;
  margin: 1.25rem 0 1.25rem 0;
  box-shadow: 0 .25rem .5rem #e6edef;

  .card-head {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #edf1f7;
    font-size: 1rem;
    font-weight: 600;
  }

  .card-body {
    padding: 1rem 1.5rem;
  }
}

mat-card:hover, mat-card:active {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
*/

.ql-snow .ql-tooltip {
  left: 0 !important;
  top: -49px !important;
}
.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "link:";
  @media (max-width: 375px) {
    content: unset;
  }
}
.ql-snow .ql-tooltip.ql-editing input[type=text] {
  @media (max-width: 375px) {
    width: 154px;
  }
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  z-index: 10 !important;
}
.ql-toolbar.ql-snow {
  border: 1px solid #212121 !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow {
  border-bottom: 1px solid #212121 !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #212121 !important;
  border-right: 1px solid #212121 !important;
  border-top: none;
}

